const balanceSlug = (slug: string, country?: string, language?: string): string => {
  const segments = slug.split('/').filter(Boolean);
  if (!segments.length) return '/';

  const deduped: string[] = [];
  for (let i = 0; i < segments.length; i += 1) {
    if (i === 0 || segments[i] !== segments[i - 1]) {
      deduped.push(segments[i]);
    }
  }

  const finalSegments: string[] = [];

if (deduped[0].includes('-')) {
  finalSegments.push(deduped[0]);
  for (let i = 1; i < deduped.length; i += 1) {
    const segment = deduped[i];
    if (
      language &&
      (segment === language ||
        (segment.includes('-') && segment.split('-')[1] === language))
    ) {
      continue;
    }
    finalSegments.push(segment);
  }
} else {
  let usedLocalePair = false;
  let idx = 0;
  const isLocalePair = (a: string, b: string) => /^[a-zA-Z]{2,3}$/.test(a) && /^[a-zA-Z]{2}$/.test(b);

  if (deduped.length >= 2 && isLocalePair(deduped[0], deduped[1])) {
    finalSegments.push(deduped[0], deduped[1]);
    usedLocalePair = true;
    idx = 2;
  }
  while (idx < deduped.length) {
    if (usedLocalePair && idx + 1 < deduped.length && isLocalePair(deduped[idx], deduped[idx + 1])) {
      idx += 2;
      continue;
    }
    finalSegments.push(deduped[idx]);
    idx += 1;
  }
}
  return `/${finalSegments.join('/')}`;
};

export default balanceSlug;
