/*
 * Configure the different component rendering options per brand.
 */

import IProduct from '../types/product';
import { LocatorFilter } from '../components/page/StoreLocator/LocatorForm';
import { AttributeOption } from '../types/productVariant';
import { ContentAlignmentType } from '../types/storyBlokComponentTypes';
import { brand } from './tokens';

export enum UIPage {
    Home,
    Category,
    Product,
    PublicationListing,
    Publication,
    ProductLine,
    DealerDetail,
    ProductLineListing,
    CampaignListing,
    Campaign,
    StoreLocator,
    Basket,
    Checkout,
    OrderConfirm,
    NotFound,
    BikeComparison,
    ContactMethodsListingPage,
    ContactMethod,
    ShippingMethodsListingPage,
    ShippingMethod,
    PaymentMethodsListingPage,
    PaymentMethod,
}

// A dark or light (default) theme can be set on certain components, which means they will use the dark/light colors
// from the design tokens (see usage below)
export enum UITheme {
    Light,
    Dark,
}

// Configure header and footer appearance across pages. This will allow you to override the default appearance for
// specific page types -- defined by UIPage. Add the page types you want to apply a rule to to a rule's set.
export interface UIConfig {
    header: {
        // Use the dark theme on the header instead of the default light theme
        darkTheme: Set<UIPage>;
    };
    footer: {
        // Use the dark theme on the footer
        darkTheme: Set<UIPage>;
    };
}

export type CountryKeys =
    | 'at'
    | 'be'
    | 'ch'
    | 'de'
    | 'default'
    | 'dk'
    | 'es'
    | 'fi'
    | 'fr'
    | 'gb'
    | 'ie'
    | 'int'
    | 'it'
    | 'nl'
    | 'se';

export type LanguageKeys = 'default' | 'de' | 'da' | 'es' | 'fi' | 'fr' | 'en' | 'it' | 'nl' | 'sv';

export type ShippingMethodType = 'shippingMethod' | 'shippingMethodAlternate';

export type TextAlignmentType = 'left' | 'center';

// Sitewide configuration for the brand.
// camelCased property names indicate a behavioral or technical configuration
// PascalCased, un-prefixed property names refer to page types
// UPrefixed or OPrefixed property names refer to OOUX components
export interface UISiteConfig {
    // The default locale for SEO link tags. Example: "en-gb" (lang-country)
    defaultHrefLang: string;
    // AWIN is used for affiliate marketing purposes and is implemented on the server and in GTM
    awinEnabled: boolean;
    urlStructure: {
        productListing: {
            // if set to true it means:
            // 1. plp urls have a '/l/' before the url path
            // 2. plp urls need to be flattered -> /l/bikes/mountain-bikes becomes /l/mountain-bikes
            urlSegmentation: {
                enabled: boolean;
                overrideSeparator?: string; // default 'l' `PRODUCT_LISTING_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT` (dont add leading or trailing slashes)
            };
        };
        productDetail: {
            // to define the main product & sub (variant) product paths we use the following setup:
            path: {
                // one or more productKey used to define the main product path, in this order, separated by the '-',
                // right now we only support products to be fetched by `id` or `sku` on SSR, so that should be present in the list if SSR enabled
                // i.e. [{ productKey: 'name' }, { productKey: 'sku' }] > 'ultra-sf-ab1234'
                // (NOTE: `parentProductSSR` will allow overriding this for only when SSR is enabled, allowing us to rol out SSR for different envs separately)
                parentProduct: { productKey: keyof IProduct; shouldHash?: boolean }[];
                parentProductSSR?: { productKey: keyof IProduct; shouldHash?: boolean }[];
                // (optional) one variantOptionCode used to define the variant product child path
                // i.e. { variantAttributeOption: FRAME_SHAPE_ATTRIBUTE } > 'low-step'
                childProduct?: { variantAttributeOption: AttributeOption };
            };

            // if set to true it means:
            // 1. pdp urls have a '/p/' before the url path
            urlSegmentation: {
                enabled: boolean;
                overrideSeparator?: string; // default 'p' `PRODUCT_DETAIL_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT` (dont add leading or trailing slashes)
            };
        };
        productLineDetail: {
            // if set to true it means:
            // 1. product line detail pages urls have a '/r/' before the url path
            urlSegmentation: {
                enabled: boolean;
                overrideSeparator?: string; // default 'r' `PRODUCT_LINE_DETAIL_PAGE_URL_SEGMENTATION_SEPARATOR_DEFAULT` (dont add leading or trailing slashes)
            };
        };
        // the character used between country and language, e.g. '-' -> fr-en
        localeSeparator: '-' | '/';
    };
    fonts?: {
        urlFile?: string[];
    };
    showPriceInternationalSalesChannel?: {
        visible?: boolean ;
    };
    Product: {
        // This is used to map external attribute keys to internal ones
        attributesKeyMap?: {
            [key in LanguageKeys | string]?: {
                [option in AttributeOption]?: string;
            };
        };
        // Allowing to display options as readonly based on their displayType value from Shopware
        // instead of making them selectable but it does not make sense from the options perspective
        allowReadOnlyOptionsAttributesByDisplayType?: boolean;
        // Additional attributes to be displayed on the product options. These will have just one value
        // instead of multiple choices. They should only be added if the option is not already present
        // in the variants. Which attributes we want to add is defined in attributeLocations.
        optionsDisplayRules?: Array<{
            attributeKeys: AttributeOption[];
            priority: number;
            force: boolean;
        }>;
        // Values of attributes could have a customsorting strategy
        attributeValueSortingStrategy?: {
            [option in AttributeOption]?: string;
        };
        // How many steps should be included in the breadcrumbs on the PDP? Home > depth 2 > depth 3 > product name
        breadcrumbsDepth: number;
        // Allowing to display icons for single options
        showIconsForSingleProduct?: AttributeOption[];
        smartfit: {
            // Show the smartfit bike measuring tool on the PDP
            visible: boolean;
            sizeAttribute: AttributeOption;
            formatSizeValue?: boolean;
        };
        geometry: {
            visible: boolean;
        };
    };
    ProductListing: {
        categoryPageSize: number;
        readMoreButtonWithTitle: boolean;
        columns: {
            // On the PLP, how many columns do we show on the PLP on xxl (breakpoint) screen sizes.
            xxl: number;
        };
        // Select which filters to include in the ProductListing. Add a "default" key to the object
        // to set the default filters for all countries. You can override countries by setting a country
        // key. Example: { default: ['colorGroup', 'attrGears'], nl: ['colorGroup'] }
        // See the Elastic Search products index for available options.
        // Note that the order of this array does not determine the order of how the filters get rendered
        filters: { [key in CountryKeys | string]?: Array<string> };
    };
    OCampaignListXL: {
        // Allow connectedVideo's to be rendered in the OCampaignListXL
        showBackgroundVideo: boolean;
    };
    OProductFull: {
        hero: {
            // Render a UProductCover on the PDP is the product has a hero image
            visible: boolean;
            theme: UITheme;
        };
        reviews: {
            // Render Yotpo user reviews on the PDP
            visible: boolean;
            // Exclude the countries for which the brand doesn't have Yotpo user reviews
            exclude: string[];
        };
        yotpoGallery: {
            // Render Yotpo gallery on the PDP
            visible: boolean;
        };
        images: {
            // Render the product images as either a carousel (horizontal srolling through the images) or in a grid
            // (vertical scrolling through the images)
            layout: 'carousel' | 'grid';
            containImages: boolean;
        };
        config: {
            theme: UITheme;
            preferOnlineSales: boolean;
            oneyPriceRange: { from?: number; to?: number };
        };
        description: {
            theme: UITheme;
        };
        keyFeatures: {
            // Enable key features on the PDP
            visible: boolean;
            theme: UITheme;
            direction: 'horizontal' | 'vertical';
            accordion: boolean;
        };
        geometry: {
            theme: UITheme;
        };
        specifications: {
            theme: UITheme;
        };
        relatedProducts: {
            theme: UITheme;
        };
        flowboxGallery: {
            visible: boolean;
        };
        bidex: { [key in CountryKeys | string]?: boolean };
    };
    Dealer?: {
        hasDetailPage?: boolean;
    };
    OBrandSignature: {
        /**
         * An array consisting of objects (color and a width in %) that represent the brand's signature.
         * For example the french flag:
         * ```
         * [{ width: 95, color: 'blue' }, { width: 2.5, color: 'white' }, { width: 2.5, color: 'red' }];
         * ```
         * It also supports color tokens;
         *
         */
        shape?: Array<{ width: number; color: string }>;
    };
    ProductConfiguratorOptions: {
        // Size the PDP configurator options to fit the available width. Will be left-aligned if set to false.
        justifyOptionLayout: boolean;
    };
    Campaign: {
        theme: UITheme;
        // Will render the OCampaignListBanner images as a full-width background hero image when set to true.
        fullSizeHero: boolean;
    };
    ProductLine: {
        theme: UITheme;
    };
    ProductLineListing: {
        theme: UITheme;
    };
    SplashPage: {
        // Include the splash page at index that allows the user to select their country on first visit
        displayExtendedVersion: boolean;
        theme: UITheme;
    };
    Home: {
        theme: UITheme;
        yotpoGallery: {
            // Render Yotpo gallery on the Homepage
            visible: boolean;
        };
    };
    Header: {
        centeredLogo: boolean;
    };
    StoreLocator: {
        // Select which filters to include in the StoreLocator. Add a "default" key to the object
        // to set the default filters for all countries. You can override countries by setting a country
        // key. Example: { default: ['traditionalBikes', 'electricBikes'], nl: ['traditionalBikes'] }
        // See the LocatorFilter type for available options.
        // Note that the order of this array determines the order of how the filters get rendered
        filters: { [key in CountryKeys]?: Array<LocatorFilter> };
        // For some countries the dealers may need to be filtered on the type property instead of the role property.
        // Add those countries as an array, example: filterByType: ['GB', 'IE']
        filterByType?: Array<CountryKeys>;
        hasCustomIcons?: boolean;
        theme: UITheme;
    };
    Checkout: {
        // Provide for each country (or 'default') which shipping method logic should be used. Options are:
        // shippingMethod: Default behavior, select shipping method from Shopware options
        // shippingMethodAlternate: If the user is not ordering a bike (accessories only), only allow homeDelivery
        shippingLogic: { [key in CountryKeys]?: ShippingMethodType };
    };
    Basket: {
        disableQuantitySelection: boolean;
    };
    FullSizeBannerContent: {
        contentAlignmentConfig: ContentAlignmentType; // This is overridden by the component's own contentAlignment prop from Storyblok
        fluidContainer: boolean;
        largeHeading: boolean;
    };
    PublicationListingContent: {
        textAlign: TextAlignmentType;
    };
    BikeComparison: {
        theme: UITheme;
    };
    ContactMethodsListingPage: {
        theme: UITheme;
    };
    ContactMethodPage: {
        theme: UITheme;
    };
    ShippingMethodsListingPage: {
        theme: UITheme;
    };
    ShippingMethodPage: {
        theme: UITheme;
    };
    PaymentMethodsListingPage: {
        theme: UITheme;
    };
    PaymentMethodPage: {
        theme: UITheme;
    };
    CookieSettings?: {
        recaptcha?: {
            // Provide for each country (or 'default') which oneTrust keys to validate.
            oneTrustKeysToValidate: { [key in CountryKeys]?: string[] };
        };
        googleMaps?: {
            // Provide for each country (or 'default') which oneTrust keys to validate.
            oneTrustKeysToValidate: { [key in CountryKeys]?: string[] };
        };
        youtube?: {
            // Provide for each country (or 'default') which oneTrust keys to validate.
            oneTrustKeysToValidate: { [key in CountryKeys]?: string[] };
        };
        oneTrustAutoblockEnabled: boolean;
    };
}
export interface UIPageConfig {
    header: {
        theme: UITheme;
    };
    footer: {
        theme: UITheme;
    };
}

// Needed for Storybook
const brandKey = brand ?? 'raleigh';

// eslint-disable-next-line import/no-dynamic-require,@typescript-eslint/no-var-requires
const uiConfig: UIConfig = require(`./uiConfig.${brandKey}`).default;

// eslint-disable-next-line
export const uiSiteConfig: UISiteConfig = require(`./uiConfig.${brandKey}`).uiSiteConfig;

export const shouldHidePriceAndPayment= (country: string) => {
    // Hide the price for the International site including all brand except Ghost to hide payment Method
   if(country === 'INT' && (uiSiteConfig.showPriceInternationalSalesChannel?.visible ?? false) === false) {
       return true;
   }
   return false;
};
export const getUIPageConfig = (uiPage: UIPage): UIPageConfig => ({
    header: {
        theme: uiConfig.header.darkTheme.has(uiPage) ? UITheme.Dark : UITheme.Light,
    },
    footer: {
        theme: uiConfig.footer.darkTheme.has(uiPage) ? UITheme.Dark : UITheme.Light,
    },
});
