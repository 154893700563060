import { siteStructureRoute, ensureCorrectCountryLanguage, productLineRoute } from '../getRoute';
import { MultiLink } from '../types/storyBlokComponentTypes';
import isExternalUrl from './isExternalUrl';
import balanceSlug from './balanceSlug';

const createLink = (
    link: MultiLink | undefined | null | string,
    country: string,
    language: string
): string | undefined => {
    if (!link) {
        return undefined;
    }

    if (typeof link === 'string') {
        return link;
    }

    const url = link.url ?? link.href;
    let finalUrl: string | undefined;

    switch (link.linktype) {
        case 'email': {
            if (url) {
                finalUrl = `mailto:${url}`;
            }
            break;
        }
        case 'asset':
        case 'url': {
            if (url) {
                finalUrl = isExternalUrl(url)
                    ? url
                    : ensureCorrectCountryLanguage(country, language, url);
            }
            break;
        }
        case 'story': {
            // @TODO: fetch translated `story.full_slug` for story by `id` OR `uuid` for the selected language if story is not defined
            const storyFullSlug = link.story?.full_slug;
            if (storyFullSlug) {
                if (storyFullSlug.includes('productlines')) {
                    finalUrl = productLineRoute(country, language, storyFullSlug);
                }
                finalUrl = siteStructureRoute({ country, language, fullSlug: storyFullSlug });
            }            
            if (!finalUrl && url) {
                finalUrl = ensureCorrectCountryLanguage(country, language, url);
            }
            break;
            }
        default: {
            if (url) {
                finalUrl = url;
            }
            break;
        }
    }

    if (!finalUrl) return undefined;
    /* If it's an absolute URL, return it as is. */
    if (/^https?:\/\//.test(finalUrl)) {
        return finalUrl;
    }
    /* Otherwise, balance (normalize) the relative URL. */
    return balanceSlug(finalUrl, country, language);
};

export default createLink;
